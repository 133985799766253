import Vue from 'vue'
import Vuex from 'vuex'
import PubNub from 'pubnub';
import CustomEventBus from '@/utils/CustomEventBus';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initialized: false,
    origRequestedUri: '/welcome',
    accessToken:null,
    eventBus: new CustomEventBus(),
    darkMode: 'automatic',
    selectLang: 'en-US',
    domain: null,
    accountId:null,
    selectedClientId:null,
    loggedInUser: {},
    leftNavExpanded: true,
    rightNavExpanded: false,
    rightNavFocus: false,
    isLoggedIn: false,
    loadingState: false,
    globalModalController: null,
    pubNub: null,
    pubNubToken: null,
    pubNubChannels: [],
    accountFonts: [],
    customPages: [],
    clientFields: null,
    contactFields: null,
    projectFields: null,
    pipelineFields: null,
    customEmbed: null,
    usersForAccount: [],
    defaultCurrency: 'USD',
    locale: 'en-US',
    weekStartsOn: 'Sunday',
    navDetailKeys: [],
    chartColors: ['#8EA3B8', '#FFA5B6', '#CE62E9', '#008556','#3BDBBE', '#51CF66', '#FEBB37', '#E86825','#FD7E14', '#FA5252', '#7950F2', '#4F72FF'],
    actionCount: 0,
    tabs: [],
    agreementBuilderState: {},
    agreementPaymentElement: null,

    alert: { type: null, message: null},
    stripeKey: process.env.VUE_APP_STRIPE_KEY,
    payPalClientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
  },

  getters: {
    getMessageChannelPrefix: (state,getters) => {
      if(getters.getClientId){
        return 'cp-' + getters.getClientId;
      }else{
        return 'c-' + state.accountId
      }
    },

    getAccountId: (state) => {
      if(state.domain && state.domain.accountId) {
        return state.domain.accountId;
      }else{
        return state.accountId;
      }
    },

    getClientAccounts(state) {
      if (state.loggedInUser) {
        return state.loggedInUser.clientAccess.clients;
      }
    },

    getClientId: (state, getters) => {
      if(getters.getClient){
        return getters.getClient.clientId;
      }else{
        return null;
      }
    },

    getClientName: (state, getters) => {
      if(getters.getClient){
        return getters.getClient.clientName;
      }else{
        return null;
      }
    },

    getClient: (state) => {
      if(state.loggedInUser && state.loggedInUser.clientAccess){
        if(state.selectedClientId){
          let client =  state.loggedInUser.clientAccess.clients.find(c => c.clientId === state.selectedClientId);
          if(client){
            return client;
          }
        }
        return state.loggedInUser.clientAccess.clients[0];
      }else{
        return null;
      }
    },

    getBaseUrl: (state, getters) => {
      if (state.domain && state.domain.podUrl) {
        return state.domain.podUrl + 'api/v1/' + getters.getAccountId + '/portal';
      } else {
        return null;
      }
    },

    getPodUrl: (state) => {
      if (state.domain && state.domain.podUrl) {
        return state.domain.podUrl
      } else {
        console.warn('Unable to locate POD, returning default');
        return 'https://pod01.withmoxie.com/';
      }
    },

    getFeatures: (state,getters) => {
      if(state.loggedInUser){
        return state.loggedInUser.clientFeatureMap[getters.getClientId];
      }else if(state.domain && state.domain.portalFeatures){
        return state.domain.portalFeatures;
      }else{
        return { } ;
      }
    },

    getFullName: (state) => {
      return state.loggedInUser.firstName + ' ' + state.loggedInUser.lastName;
    },

    getCustomBranding: (state) => {
      if(state.domain){
        return state.domain.customBranding;
      }else{
        return {};
      }
    }
  },

  mutations: {
    setDomain(state,domain){
      state.domain = domain;
    },

    setSelectedClient(state,clientId){
      state.selectedClientId = clientId;
    },

    setInitialized(state, initialized) {
      state.initialized = initialized;
    },

    setAccessToken(state,authenticatedUser){
      state.accessToken = authenticatedUser.authToken;
      state.pubNubToken = authenticatedUser.pubNubToken;
      if(state.pubNub){
        state.pubNub.setToken(state.pubNubToken);
      }
    },

    setOrigRequestedUri(state,uri){
      state.origRequestedUri = uri;
    },

    setContactRecord(state,contact){
      state.contact = contact;
      state.loggedInUser.userId = contact.id;
    },

    setLoggedInUser(state,user){
      state.loggedInUser = user;
      state.isLoggedIn = true;

      if(state.pubNub == null) {
        state.pubNub = new PubNub({
          subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE,
          publishKey: process.env.VUE_APP_PUBNUB_PUBLISH,
          uuid: user.pubNubAuth,
          logVerbosity: false,
          auto_reconnect: true,
          restore: true,
        });

        state.pubNub.setToken(state.pubNubToken);
        state.pubNub.setHeartbeatInterval(60);

        // add a status listener
        state.pubNub.addListener({
          status: (s) => {console.log('Status', s) }
        });

        for(let i=0; i < state.loggedInUser.clientAccess.clients.length; i++){
          let clientId = state.loggedInUser.clientAccess.clients[i].clientId;
          state.pubNubChannels.push('cp-' + clientId + '.*');
        }

        let subscription = state.pubNub.subscriptionSet({
          channels: state.pubNubChannels,
        });

        state.pubNub.addListener({
          //This function will broadcast on the specific channel as well as a more broad channel
          //for the event.  a-1016.p-123445 will also broadcast on a-1016.p to allow for broader
          //event handling based solely on the category of message and not the ID
          message: function (msg) {
            //console.log(msg.channel,msg);

            state.eventBus.$emit(msg.channel, msg); //broadcast on original channel

            let parts = msg.channel.split('.');
            let channel = '';
            for (let i = 0; i < parts.length; i++) {
              if (channel === '') {
                channel = parts[i];
              } else {
                channel = channel + '.' + parts[i];
              }

              if (channel !== msg.channel) {
                state.eventBus.$emit(channel, msg);
              }

              let truncated = channel.split('-');
              if (truncated.length > 1) {
                truncated.pop();
                truncated = truncated.join('-');
                state.eventBus.$emit(truncated, msg);
              }
            }
          },
        });

        subscription.subscribe();
      }
    },

    logout(state){
      state.isLoggedIn = false;
      state.loggedInUser = {};
      state.accessToken = null;
    },

    setAccountId(state,id){
      state.accountId = id;
    },

    setUserId(state,id){
      state.loggedInUser.userId = id;
    },

    startLoading(state){
      state.loadingState = true;
    },

    stopLoading(state){
      state.loadingState = false;
    },

    success(state, message){
      state.alert = {
        type: 'success',
        message: message
      }
    },

    info(state, message){
      state.alert = {
        type: 'info',
        message: message
      }
    },

    warn(state, message){
      state.alert = {
        type: 'warning',
        message: message
      }
    },

    error(state, message) {
      state.alert = {
        type: 'error',
        message: message
      }
    },
  },

  actions: {
  },
})
